<template>
  <Flickity v-if="tabList" class="tabs2" :options="flickityOptions">
    <template>
      <div
        class="item"
        :class="{ active: currentRegion == 'all' }"
        @click="$emit('getAllRegion')"
      >
        {{ $t("all") }}
      </div>
      <div
        class="item"
        v-for="(item, idx, key) in tabList"
        :key="key"
        @click="getCurrentRegion(idx, key)"
        :class="{ active: key == currentIdx && currentRegion != 'all' }"
      >
        {{ item }}
      </div>
    </template>
  </Flickity>
</template>

<script>
import Flickity from "vue-flickity";
export default {
  components: {
    Flickity
  },
  props: {
    tabList: {
      type: [Array, Object],
      require: true,
      default: null
    },
    currentRegion: {
      type: String
    }
  },
  data() {
    return {
      currentIdx: 0,
      flickityOptions: {
        // prevNextButtons: false,
        pageDots: false,
        contain: true,
        cellAlign: "center",
        groupCells: true
      }
    };
  },
  methods: {
    getCurrentRegion(region, key) {
      this.$emit("getCurrentRegion", region);
      this.currentIdx = key;
    }
  }
};
</script>

<style lang="sass">
.tabs2
    @media (max-width: 576px)
        width: calc(100% - 30px)
        margin: 0 auto
        margin-top: 30px !important
.flickity-button
    padding: 0
    background-color: #2a4651
    transform: unset
    border-radius: 0
    width: 40px
    height: unset
    font-style: normal
    font-weight: 500
    font-size: 12px
    line-height: 100%
    color: $c-btn
    box-shadow: 0 0 0px 1px inset $c-btn
    svg
        height: 12px
    &:disabled
        opacity: 0

    .flickity-button-icon
        width: 15px
        left: unset
        right: unset
        top: unset
        bottom: unset
        position: static
.previous
    left: 0 !important
    bottom: 0
    top: 0
.next
    right: 0 !important
    bottom: 0
    top: 0

.item
    padding: 12px 20px
    font-style: normal
    font-weight: 500
    font-size: 12px
    line-height: 100%
    color: $c-btn
    box-shadow: 0 0 0px 1px inset $c-btn
    &.router-link-exact-active.router-link-active, &.active
        color: #17171D
        font-weight: 600
        background-color: #4AA7BC
</style>
