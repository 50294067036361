<template>
    <div>
    <h1 class="sectionTitle bef" v-html="title"></h1>
    <p class="descr" v-html="descr"></p>
    </div>
</template>

<script>
    export default {
        props: {
            title: {
                type: String,
            },
            descr: {
                type: String
            }
        },
    }
</script>

<style lang="sass" scoped>
.smallmb
    .descr
        margin-bottom: 17px
</style>
